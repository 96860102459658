<template>
  <van-form >
    <van-field name="open" label="启用">
      <template #input>
        <van-switch v-model="form.open" size="20" />
      </template>
    </van-field>
    <van-field
        v-model="form.name"
        label="规则名称"
        name="规则名称"
        placeholder="规则名称"
        :rules="[{ required: true, message: '请填写规则名称' }]"
    />

    <van-field
        v-model="form.phoneNumber"
        rows="2"
        autosize
        label="电话号码"
        type="textarea"
        placeholder="请输入电话号码(一行1个)"
        :rules="[{ required: true, message: '请填写电话号码' }]"
    />

    <van-field name="smsAlarm" label="短信通知">
      <template #input>
        <van-switch v-model="form.smsAlarm" size="20" />
      </template>
    </van-field>
    <van-field name="telephoneAlarm" label="电话通知">
      <template #input>
        <van-switch v-model="form.telephoneAlarm" size="20" />
      </template>
    </van-field>


<!--    <van-field v-model="form.dntDuration" type="digit" label="防打扰设置" />-->
    <div style="margin: 16px;">
      <van-row  gutter="20">
        <van-col span="7"><van-button plain type="danger" @click="back" round block>返 回</van-button></van-col>
        <van-col span="15"><van-button round block type="info" native-type="submit" @click="onSubmit" >{{this.form.id?$t("确认修改"):$t("确认添加")}}</van-button></van-col>
      </van-row>
    </div>
  </van-form>
</template>

<script>
import {List, Cell, Icon, Button, SwipeCell, Form, Field, Switch, Row, Col, Notify} from 'vant';
import {getPhonealarmrules,addPhonealarmrules,updatePhonealarmrules} from "../../api/phonealarmrules";
export default {
  components:{
    [List.name]:List,
    [Cell.name]:Cell,
    [Icon.name]:Icon,
    [Button.name]:Button,
    [SwipeCell.name]:SwipeCell,
    [Form.name]:Form,
    [Field.name]:Field,
    [Switch.name]:Switch,
    [Col.name]:Col,
    [Row.name]:Row,
  },
  name: "edit",
  data() {
    return {
      username: '',
      password: '',
      form:{
        id:this.$route.params.id,
        open:true,
        smsAlarm:true,
        telephoneAlarm:true,
      },

    };
  },
  methods: {
    onSubmit() {
     let form = this.form;
     if(form.id == null){
        //新增
       addPhonealarmrules(form).then( () => {
         Notify({ type: 'success', message: this.$t('数据保存成功'),duration:500 })
       })
     }else{
       //修改
       updatePhonealarmrules(form).then( () => {
         Notify({ type: 'success', message: this.$t('数据更新成功'),duration:500 })
         this.back();
       })
     }

    },
    back(){
      window.history.back();
      // this.$router.push({
      //   name:"phonealarmrules/index"
      // })
    },
    getData(){
      getPhonealarmrules(this.form.id).then((resp => {
        this.form = resp.data
      }));
    }
  },
  created() {
    window.console.log("created",this.form.id)
    if(this.form.id){
      this.getData();
    }

  },

}
</script>

<style scoped>

</style>
